import styled, { css } from 'styled-components'

export const Button = styled.button`
  min-width: 145px;
  max-width: 300px;
  display: inline-block;
  border-radius: 999px;
  padding: 0.6rem 0;
  margin: 0.5rem 0;
  margin-right: ${props => props.rightMargin};
  background: transparent;
  color: white;
  border: 2px solid white;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;

  &:hover {
    background: #fff;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 0.5rem 15px;
  }

  ${props => props.primary && css`
    background: linear-gradient(to top right, #1a6bdd 0%,#45a7f7 100%);
    border: none;

    &:hover {
      background: linear-gradient(to top right, #176bdd 0%,#4577f7 100%);
    }
  `}

  ${props => props.outlined && css`
    background: transparent;
    border: 2px solid #fff;
    padding: 0.5rem 0;

    &:hover {
      background: #fff;
      color: #333;
    }
  `}

  ${props => props.disabled && css`
    background: #ccc;
    color: #000;
    cursor: not-allowed;

    &:hover {
      background: #ccc;
      color: #000;
    }
  `}
`
