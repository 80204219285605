import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SuccessWrapper = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const SvgWrapper = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #83b572;
    border-radius: 50%;

    svg {
        font-size: 25px;
    }
`

const FormSuccess = () => {
    return (
        <SuccessWrapper>
            <SvgWrapper>
                <FontAwesomeIcon icon={['fa', 'check']} color="#fff" size="lg" />
            </SvgWrapper>
            <h2>Thanks for reaching out.</h2>
            <p>I should be in contact shortly.</p>
        </SuccessWrapper>
    )
}

export default FormSuccess
