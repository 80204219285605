import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'
import Recaptcha from 'react-recaptcha' 
import { Button } from '../styles/buttons'
import FormSuccess from './FormSuccess'

const FormContainer = styled.div`
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 33px 33px 70px 0 rgba(0,0,0,.22);
    padding: 35px;
    grid-column: span 2;

    @media screen and (max-width: 1100px) {
        grid-column: span 1;
    }

    .form-group {
        position: relative;
        margin-top: 2.25rem;
        margin-bottom: 2.25rem;

        .form-error {
            margin: 7px 0;
            font-style: italic;
            font-size: .8em;
            color: #ea4c5a;
        }
    }

    input {
        display: block;
        background: none;
        padding: .125rem .125rem .0625rem;
        font-size: 1rem;
        border-width: 0;
        border-color: transparent;
        line-height: 1.9;
        width: 100%;
        color: transparent;
        transition: all .28s ease;
        box-shadow: none;

        &:focus {
            outline: none;
            color: #34424a;

            &~span {
                font-size: .8rem;
                color: #61a9e2;
                top: -1rem;
                left: 0;
            }

            &~.bar {

                &:before {
                    width: 100%;
                    left: 0;
                }
            }
        }

        &:not(:placeholder-shown) {
            color: #34424a;

            &~span {
                font-size: .8rem;
                color: #61a9e2;
                top: -1rem;
                left: 0;
            }
        }
    }

    span {
        position: absolute;
        top: .25rem;
        pointer-events: none;
        padding-left: .125rem;
        z-index: 1;
        color: #34424a;
        font-size: 1rem;
        font-weight: 400;
        transition: all .28s ease;
    }

    .bar {
        position: relative;
        border-bottom: 2px solid #34424a;
        display: block;

        &:before {
            content: "";
            height: 2px;
            width: 0;
            left: 50%;
            bottom: -2px;
            position: absolute;
            background: #61a9e2;
            transition: left .28s ease,width .28s ease;
            z-index: 2;
        }
    }

    select {
        width: 100%;
        font-size: 1rem;
        height: 1.9rem;
        padding: .125rem .125rem .0625rem;
        background: none;
        border: none;
        line-height: 1.6;
        box-shadow: none;

        &~span {
            font-size: .8rem;
            color: #61a9e2;
            top: -1rem;
            left: 0;
        }
    }
`

const encodeFormData = (data) => {
    
    const testRegx = new RegExp('^[0-9]+$')

    const ecodedStr = Object.keys(data)
    .map(key => {
        if (!testRegx.test(key)) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(data[key].value)}`
        }
    }).filter(Boolean).join('&')

    return ecodedStr
}

const DisplayingErrorMessagesSchema = Yup.object().shape({
    fullName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    company: Yup.string()
        .min(2, 'Too short!')
        .max(70, 'Too long!'),
    websiteUrl: Yup.string()
        .min(4, 'Too short')
        .max(120, 'Too long!'),
    emailAddress: Yup.string()
        .email('Invalid email')
        .required('Required'),
  });

const ContactForm = ({ serviceData, formName = 'contact-form' }) => {
    const [data, setData] = useState([])
    const [token, setToken] = useState(null)
    const [formStatus, setFormStatus] = useState(false);
    const contactFormRef = useRef(null)

    useEffect(() => {
        const handleDataChange = () => {
            setData(serviceData.edges)
        }

        return handleDataChange()        
    }, [serviceData.edges])

    const handleSubmit = (e) => {
        e.preventDefault()

        const formData = contactFormRef.current.elements
        const encodedFormData = encodeFormData(formData)

        if (token) {
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: `${encodedFormData}`
            })
            .then(res => {
                // success
                setFormStatus(true)
            })
            .catch(err => {
                // error
                console.log(err)
            })
        }
    }

    return (<FormContainer>
        {!formStatus ? (<>
            <Formik
                initialValues={{ fullName: '', company: '', emailAddress: '' }}
                validationSchema={DisplayingErrorMessagesSchema}
            >
                {({ errors, touched, setTouched, validateForm, values }) => (
                    <Form ref={contactFormRef} name="contact-form" method="POST" data-netlify="true" onSubmit={(e) => handleSubmit(e)} data-netlify-recaptcha="true">
                        <input type="hidden" name="contact-form" value="contact-form" />
                        <div className="form-group">
                            <Field id="fullName" name="fullName" type="text" placeholder=" " />
                            <span htmlFor="fullName">Full Name</span>
                            <i className="bar" />
                            {touched.fullName && errors.fullName && <div className="form-error">{errors.fullName}</div>}
                        </div>

                        <div className="form-group">
                            <Field type="text" id="company" name="company" placeholder=" " />
                            <span htmlFor="company">Company</span>
                            <i className="bar" />
                            {touched.company && errors.company && <div className="form-error">{errors.company}</div>}
                        </div>

                        <div className="form-group">
                            <Field type="text" id="websiteUrl" name="websiteUrl" placeholder=" " />
                            <span htmlFor="websiteUrl">Website/App Link</span>
                            <i className="bar" />
                            {touched.websiteUrl && errors.websiteUrl && <div className="form-error">{errors.websiteUrl}</div>}
                        </div>

                        <div className="form-group">
                            <Field type="text" id="emailAddress" name="emailAddress" placeholder=" " />
                            <span htmlFor="emailAddress">Email</span>
                            <i className="bar" />
                            {touched.emailAddress && errors.emailAddress && <div className="form-error">{errors.emailAddress}</div>}
                        </div>

                        {data ? (
                            <div className="form-group">
                                <Field as="select" name="projectType">
                                    {data && data.map((service, index) => (
                                        <option key={`service${index}`} value={service.node.slug}>{service.node.title}</option>
                                    ))}
                                    <option value="other">Other</option>
                                </Field>
                                <span htmlFor="projectType">Project Type</span>
                                <i className="bar" />
                                {touched.projectType && errors.projectType && <div className="form-error">{errors.projectType}</div>}
                            </div>
                        ) : ''}

                        <div className="form-group">
                            <Field type="textarea" id="message" name="message" placeholder=" " />
                            <span htmlFor="message">Message</span>
                            <i className="bar" />
                        </div>

                        <div className="form-group">
                            <Field type="text" id="hearAbout" name="hearAbout" placeholder=" " />
                            <span htmlFor="hearAbout">Where did you hear about me?</span>
                            <i className="bar" />
                        </div>

                        <div className="form-group" netlify-recaptcha="true">
                            <Recaptcha
                                sitekey={process.env.SITE_RECAPTCHA_KEY}
                                render="explicit"
                                verifyCallback={response => setToken(response)}
                                onloadCallback={() => console.log('done loading!')}
                            />
                        </div>
                        <Button primary type="submit" onClick={e => {
                            setTouched(contactFormRef.current.elements, true)
                           
                            validateForm(values).then(res => {
                                if (Object.keys(res).length === 0) {
                                    console.log('here')
                                    handleSubmit(e)
                                }
                            }).catch(err => {

                            })
                        }}>Submit</Button>
                    </Form>
                )}
            </Formik>
        </>) : (
            <FormSuccess />
        )}

    </FormContainer>
)}

ContactForm.propTypes = {
    serviceData: PropTypes.node.isRequired
}

export default ContactForm
